import React from "react"
import {Link} from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ProseDark from "../../components/prose-dark"
import axios from 'axios'
import ProseLight from "../../components/prose-light"

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      agreement: false,
      captcha: undefined,
      recaptchaInstance: undefined,
      formError: [],
      formSuccess: '',
    };
  }

    componentDidMount() {
      if(this.state.recaptchaInstance) {
          this.state.recaptchaInstance.reset();
      }
  }

  submit = (event) => {
        event.preventDefault()
        this.setState( { formSuccess: '', formError: [] }, this.handleFormEntry )

    }

    handleFormEntry = () => {

        // console.log(this.props.title)

        if( this.state.name     === '' ||
            this.state.email    === '' ||
            this.state.message  === ''
          ) {
            this.setState({ formError: this.state.formError.concat(["Form not filled out completely."]) })
        } else {
            let formData = new FormData() 
            formData.set('name', this.state.name)
            formData.set('email', this.state.email)
            formData.set('message', this.state.message)
            // formData.set('website', this.props.title)
            formData.set('website', 'Touchstone')
            formData.set('response', this.state.captcha)

            axios.post('https://backend.touchstonedistrictservices.com/mailer', formData, 
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    }
                }).then( function(response) {
                    // console.log('Response');
                    // console.log(response.data);
                    if(response.data.status === "success") {
                        this.clearForm()
                        this.setState({ formSuccess: "Your message has been sent." })
                    } else {
                        this.setState({ formError: this.state.formError.concat(["Something went wrong, check your entry and try again."]) })
                    }

            }.bind(this) ).catch( function(error) {
                console.log(error.response)
            })

            if(this.state.recaptchaInstance) {
                this.state.recaptchaInstance.reset();
            }

        }

    }

    clearForm = () => {
        this.setState({ name: '', email: '', message: ''})
    }

    changeName = (event) => {
        this.setState({ name: event.target.value })
    }
    changeAgreement = (event) => {
        this.setState({ agreement: event.target.checked })
    }
    changeEmail = (event) => {
        this.setState({ email: event.target.value })
    }
    changeMessage = (event) => {
        this.setState({ message: event.target.value })
    }
    captchaVerify = (response) => {
        this.setState({ captcha: response })
    }
    onLoadRecaptcha = () => {
        if(this.state.recaptchaInstance) {
            this.state.recaptchaInstance.reset();
        }
    }
  
  render() {

  return(
  <Layout>
    <SEO title="Contact Us" />
    <div className="bg-midnight w-full min-h-screen px-8 py-36">
    <div className="m-auto w-full container">
      <ProseDark>
        <div className="grid md:grid-cols-2 gap-12">
        <div>
        <div className="text-base mb-4 -mt-4">
          <Link className="inline-block no-underline hover:text-yellow-400 hover:underline text-white font-normal" to="/">Home</Link>
          <span className="opacity-50"> <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Contact Us</span>
        </div>
        <h1 className="text-6xl md:text-8xl !mb-0 leading-none">Let's chat<span className="text-yellow-400">.</span>
</h1>
    <p className="text-lg pt-8"><a className="no-underline" href="tel:+18325585714">832 558 5714</a><br /><a className="no-underline" href="mailto:info@touchstonedistrictservices.com">info@touchstonedistrictservices.com</a></p>
    <p className="text-base">8118 Fry Rd., Ste. 703,<br />Cypress, TX 77433</p>
    </div>
        <div>

        <p>Contact us today to help elevate and modernize the way your district thinks about communication.

</p>
                <form name="contact" onSubmit={this.submit}>
                    { this.state.formError.length !== 0 ? (<ul className="list-none text-red-400  border-red-400 border-2 rounded-3xl">{this.state.formError.map((error) => (
                        <li className="" key={error} >{error}</li>
                    )) }</ul>): < ></ > }
                    { this.state.formSuccess === '' ? ( < ></ > ) : (<ul className="list-none text-green-400 border-green-400  border-2 rounded-3xl"><li>{this.state.formSuccess}</li></ul>)}

                    <div className="mb-2">
                        <label htmlFor="name" className="text-sm uppercase pl-6">Name</label>
                        <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6 text-xl" type="text" name="name" placeholder="Enter your name" onChange={this.changeName} value={this.state.name} />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="email" className="text-sm uppercase pl-6">Email</label>
                        <div className="control">
                            <input className="block w-full rounded-full bg-zinc-800 border-0 pl-6 text-xl" type="email" name="email" placeholder="Enter your email" onChange={this.changeEmail} value={this.state.email} />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="text-sm uppercase pl-6">Message</label>
                        <div className="control">
                            <textarea className="block w-full h-36 rounded-3xl bg-zinc-800  border-0 pl-6 text-xl" name="message" placeholder="Enter your message" onChange={this.changeMessage} value={this.state.message}></textarea>
                        </div>
                    </div>
                    <div className="">
                        <div className="control">
                            <input className="transition-all inline-block text-black text-xl font-medium uppercase bg-yellow-400 hover:bg-yellow-300  mr-1 mb-2 px-4 py-2 rounded-full leading-none" type="submit" name="submit" value="Submit" />
                        </div>
                    </div>
                </form>
        </div>
        </div>
      </ProseDark>
    </div>
    </div>
  </Layout>
)
}
}

export default ContactPage
